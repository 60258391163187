import { filterTypes, storageItemName } from './config';
import isEqual from 'lodash.isequal';
import * as storage from './browserStorage';

/**
 * Converts string boolean values to actual booleans.
 * Converts string number values to actual numbers.
 *
 * @param {object} atts Shortcode attributes.
 * @returns {object}
 */
export const shortcodeAttsHelper = (atts) =>
    Object.fromEntries(
        Object.entries(atts).map(([att, value]) => {
            if (value === 'true') {
                return [att, true];
            } else if (value === 'false') {
                return [att, false];
            } else if (typeof value === 'string' && !isNaN(value)) {
                return [att, Number(value)];
            } else {
                return [att, value];
            }
        })
    );

/**
 * Remove excess whitespace and special characters, convert to lowercase and replace spaces with dashes.
 * "This is a String!" -> "this-is-a-string"
 *
 * @param {string} str
 * @returns {string}
 */
export const toLowerWithDashes = (str) =>
    str
        .replace("'", '')
        .replace(/[\W_\s]+/g, ' ')
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase();

/**
 * Gets existing checkout info from localStorage, if it exists.
 * @returns {object|false}
 */
export const getExistingCheckout = () => {
    const checkout = storage.getItem(storageItemName);

    if (typeof checkout === 'string' && checkout !== 'undefined') {
        return JSON.parse(checkout);
    } else {
        return false;
    }
};

/**
 * Get initial menu filter object.
 *
 * @param {object} params useParams
 * @param {object} filterTerms Object containing arrays of filter terms.
 * @param {object} menuFilter Default filter from globalConfig.
 *
 * @returns {object}
 */
export const getInitialMenuFilter = (
    params,
    filterTerms,
    menuFilter,
    searchQuery
) => {
    const filters = Object.assign({}, menuFilter);

    if (params?.categoryType) {
        switch (params?.categoryType.toLowerCase()) {
            case 'special':
                if (params?.categoryName) {
                    filters.specials = true;
                }
                break;
            case 'brand':
                if (params?.categoryName) {
                    const brandId = brandIdLookup(
                        params.categoryName,
                        filterTerms.brands
                    );

                    if (brandId) {
                        filters.brandIds = [brandId];
                    }
                }
                break;
            case 'specials':
                filters.specials = true;

                if (
                    params?.categoryName?.toLowerCase() === 'brand' &&
                    params?.specialCategoryName
                ) {
                    const brandId = brandIdLookup(
                        params.specialCategoryName,
                        filterTerms.brands
                    );

                    if (brandId) {
                        filters.brandIds = [brandId];
                    }
                }
            case 'tags':
                filters.tags = [params?.categoryName];
                break;
            default:
                if (
                    params?.specialCategoryName &&
                    params?.categoryName?.toLowerCase() === 'category'
                ) {
                    const [filterType, filterName] = verifiedParams(
                        params.categoryName,
                        params.specialCategoryName,
                        filterTerms
                    );

                    if (filterType && filterName) {
                        filters[filterType] = filterName;
                    }
                } else if (params?.categoryName) {
                    const [filterType, filterName] = verifiedParams(
                        params.categoryType,
                        params.categoryName,
                        filterTerms
                    );

                    if (filterType && filterName) {
                        filters[filterType] = filterName;
                    }
                }
                break;
        }
    }

    if (searchQuery) {
        filters.search = searchQuery;
    }

    return filters;
};

/**
 * Remove empty `menuFilter` items, as well as specials property.
 * Used before sending in a GQL query.
 *
 * @param {object} providedMenuFilter
 * @param {object} menuFilter Default menu filter from global config.
 * @returns {object}
 */
export const cleanMenuFilterForQuery = (providedMenuFilter, menuFilter) => {
    const clone = Object.assign({}, providedMenuFilter);
    delete clone.specials;

    // only returns keys that are strings or arrays with one or more values, or potency.
    return Object.fromEntries(
        Object.entries(clone).filter(([k, v]) => {
            return (
                ((typeof v === 'string' || Array.isArray(v)) && v.length > 0) ||
                (k === 'potencyThc' && !isEqual(v, menuFilter.potencyThc)) ||
                (k === 'potencyCbd' && !isEqual(v, menuFilter.potencyCbd))
            );
        })
    );
};

/**
 * Checks if query is only filtered for brands.
 * @param {object} providedMenuFilter Current menu filter.
 * @param {object} menuFilter Default menu filter from global config.
 * @returns {bool}
 */
export const isOnlyBrandQuery = (providedMenuFilter, menuFilter) => {
    const cleanMenu = cleanMenuFilterForQuery(providedMenuFilter, menuFilter);
    const keys = Object.keys(cleanMenu);

    return keys.length === 1 && keys[0] === filterTypes.brands;
};

/**
 * Checks if query has no filters.
 * @param {object} providedMenuFilter Current menu filter.
 * @param {object} menuFilter Default menu filter from global config.
 * @returns {bool}
 */
export const isEmptyFilter = (providedMenuFilter, menuFilter) => {
    const cleanMenu = cleanMenuFilterForQuery(providedMenuFilter, menuFilter);
    const keys = Object.keys(cleanMenu);

    return keys.length === 0;
};

/**
 * If no retailer data from shortcode, get stored id or return false to trigger modal.
 * Otherwise, return the provided id. If error, return stored id, then false to trigger modal.
 *
 * @param {object} retailer Retailer shortcode data.
 * @param {object} params useParams params.
 * @param {object} retailers Retailer config settings.
 *
 * @returns {string|false}
 */
export const getRetailerId = (retailerShortcode, params, retailers) => {
    if (params?.viewName === 'shop') {
        if (params?.locationSlug) {
            const retailerMatch = Object.values(retailers).find(
                (retailer) => retailer.menuSlug === params.locationSlug
            );

            if (retailerMatch) {
                return retailerMatch.id;
            } else {
                return false;
            }
        } else {
            return getExistingCheckout().retailerId || false;
        }
    } else if (!retailerShortcode) {
        return getExistingCheckout().retailerId || false;
    }

    return (
        retailerShortcode?.providedId ||
        getExistingCheckout().retailerId ||
        false
    );
};

/**
 * Get brand ID from URL param brand-name.
 *
 * @param {string} brandName :catergoryType param
 * @param {array} brandTerms filterTerms.brands
 * @param {bool} returnObj return entire brand object, or just ID. default false (just id).
 *
 * @returns {string|false}
 */
export const brandIdLookup = (brandName, brandTerms, returnObj = false) => {
    const brandWords = brandName.split('-');

    const brand = brandTerms.find((term) => {
        let termWords = toLowerWithDashes(term.name).split('-');
        return brandWords.every((word) => termWords.includes(word));
    });

    if (brand && !returnObj) {
        return brand.id;
    } else if (brand && returnObj) {
        return brand;
    } else {
        return false;
    }
};

/**
 * Checks for valid category/strain/effect params and returns values to use in filter.
 *
 * @param {string} type params.categoryType
 * @param {string} name params.categoryName
 * @param {object} filterTerms filterTerms for this retailer
 *
 * @returns {array}
 */
export const verifiedParams = (type, name, filterTerms) => {
    /**
     * key is what is expected from :categoryType param.
     */
    const validTypes = {
        category: {
            filterTerm: 'categories',
            filterType: 'category',
        },
        strain: { filterTerm: 'strains', filterType: 'strainType' },
        effect: { filterTerm: 'effects', filterType: 'effects' },
        specials: { filterTerm: 'categories', filterType: 'category' },
    };

    const isValidType = validTypes.hasOwnProperty(type.toLowerCase());

    if (isValidType) {
        const isValidName = filterTerms[
            validTypes[type.toLowerCase()].filterTerm
        ].includes(name.toUpperCase().replace('-', '_'));

        if (isValidName) {
            const filterType = validTypes[type.toLowerCase()].filterType;
            return [
                filterType,
                filterType === 'effects'
                    ? [name.toUpperCase().replace('-', '_')]
                    : name.toUpperCase().replace('-', '_'),
            ];
        }
    }

    return [false, false];
};

/**
 * Convert a string or an array of strings to uppercase words like in PHP ucwords()
 * @param {string|string[]} strOrArray
 * @returns {string|string[]}
 */
export const ucwords = (strOrArray) => {
    if (typeof strOrArray === 'string') {
        return strOrArray.charAt(0).toUpperCase() + strOrArray.slice(1).toLowerCase();
    } else if (Array.isArray(strOrArray)) {
        return strOrArray.map(function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        });
    }
    return strOrArray;
};

/**
 * Get today's day e.g. 'Friday'.
 * @returns string
 */
export const today = () => {
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    return days[new Date().getDay()];
};

/**
 * Determines whether store is open or closed.
 * @see https://stackoverflow.com/a/26078713
 * @param {object} todaysHours retailerData hours object.
 * @returns {bool}
 */
export const isOpen = (todaysHours) => {
    if (!todaysHours.active) {
        return false;
    }

    let { start, end } = todaysHours;

    function timeToDecimal(str) {
        let pm = false;

        let parts = str.split(':');

        if (parts.length <= 1) {
            return false;
        }

        let dec = parts[1].split(' ');

        if (dec[1].includes('PM') && parts[0] !== '12') {
            pm = true;
        }

        dec = parseInt((dec[0] / 6) * 10, 10);

        let float = parseFloat(
            parseInt(parts[0], 10) + '.' + (dec < 10 ? '0' : '') + dec
        );

        if (pm) float += 12;

        return float;
    }

    start = timeToDecimal(start);
    end = timeToDecimal(end);

    let now = timeToDecimal(
        new Date().toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        })
    );

    return now < end && now > start;
};

/**
 * Scroll to the shop container.
 * @returns {void}
 */
export const scrollToShop = (customId = null, fixedId = null) => {
    const elementId = customId !== null ? customId : 'shop';
    const shopElement = document.getElementById(elementId);

    if (shopElement) {
        if (fixedId) {
            const fixedElement = document.getElementById(fixedId);
            const topFixedPosition = shopElement.offsetTop - 150;
            fixedElement.scrollTo({
                top: topFixedPosition,
                behavior: 'smooth',
            });
        } else {
            const topPosition =
                shopElement.getBoundingClientRect().top +
                window.pageYOffset -
                150;
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    }
};

/**
 * Hide the menu banner.
 * Used if navigating or filtering to a category - no need to display the menu banner that takes up real estate.
 * @returns {void}
 */
export const hideMenuBanner = () => {
    const banner = document.getElementById('headlessMenuBanners');

    if (banner) {
        banner.style.display = 'none';
    }
};

/**
 * Reformat know categories for better reading in HTML output.
 * "PRE_ROLLS" -> "Pre-Rolls"
 *
 * @param {string} str
 * @returns {string}
 */
export const formatCategory = (str) => {
    const lookup = {
        ACCESSORIES: 'Accessories',
        APPAREL: 'Apparel',
        CBD: 'CBD',
        CONCENTRATES: 'Concentrates',
        EDIBLES: 'Edibles',
        FLOWER: 'Flower',
        ORALS: 'Orals',
        PRE_ROLLS: 'Pre-Rolls',
        SEEDS: 'Seeds',
        TINCTURES: 'Tinctures',
        TOPICALS: 'Topicals',
        VAPORIZERS: 'Vaporizers',
    };

    return lookup[str] || ucwords(str);
};

/**
 * SEO-friendly tags.
 *
 * @param {object} selectedFilters
 * @param {object} filterTerms
 *
 * @returns {string}
 */
export const getOrderText = (selectedFilters, filterTerms) => {
    let selectedBrandName = '';
    let orderText = 'Shop & Order';

    if (selectedFilters && selectedFilters[filterTypes.brands].length > 0) {
        const selectedBrandId = selectedFilters[filterTypes.brands][0];
        const selectedBrand = filterTerms?.brands?.find(
            (brand) => brand.id === selectedBrandId
        );
        if (selectedBrand && selectedBrand.name) {
            selectedBrandName = selectedBrand.name;
        }
    }

    if (selectedFilters && selectedFilters[filterTypes.category]) {
        orderText += ` ${formatCategory(
            selectedFilters[filterTypes.category]
        )}`;
    }

    orderText += ' Online';

    if (selectedBrandName) {
        orderText = `${selectedBrandName} | ` + orderText;
    }

    return orderText;
};

/**
 * Check for stale session and reload.
 * @return {bool}
 */
export function checkStaleSession() {
    const oneDay = 60 * 60 * 24 * 1000;
    const existingCheckout = getExistingCheckout();

    if (existingCheckout && existingCheckout.createdAt) {
        const { retailerId, checkoutId, createdAt } = existingCheckout;
        if (Date.now() - createdAt > oneDay) {
            storage.setItem(
                storageItemName,
                JSON.stringify({
                    checkoutId,
                    retailerId,
                    createdAt: Date.now(),
                })
            );
            location.reload();
            return true;
        }
    }

    return false;
}

/**
 * Parse an embed script tag.
 * Used for reconstructing embed in fallback events.
 *
 * @param {string} scriptString
 * @returns {object}
 */
export const parseScriptTag = (scriptString) => {
    console.log(scriptString);

    const scriptRegex = /<script\s+([\s\S]*?)><\/script>/i;
    const attributeRegex =
        /(\w+)=["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))+.)["']?/g;

    const scriptMatch = scriptString.match(scriptRegex);
    console.log(scriptMatch);

    if (scriptMatch && scriptMatch[1]) {
        const attributeString = scriptMatch[1];
        const scriptAttributes = {};

        let attributeMatch;
        while ((attributeMatch = attributeRegex.exec(attributeString))) {
            const attrName = attributeMatch[1];
            const attrValue = attributeMatch[2];
            scriptAttributes[attrName] = attrValue;
        }

        return scriptAttributes;
    }

    return null;
};

/**
 * Convert 2-letter state abbreviations to the full state name.
 * Used for the location gate.
 *
 * @param {string} stateName
 * @returns {string}
 */
export const convertStateCode = (stateName) => {
    const stateCodes = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
        DC: 'District of Columbia',
    };

    return stateCodes[stateName.toUpperCase()] || stateName;
};
