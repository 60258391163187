import { sentryCapture } from './errorHandlers';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 * @param {string} type
 * @returns
 */
function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}

function getAvailableStorage() {
    if (storageAvailable('localStorage')) {
        return 'localStorage';
    } else {
        return 'sessionStorage';
    }
}

export function setItem(key, value) {
    try {
        const type = getAvailableStorage();
        window[type].setItem(key, value);
    } catch (e) {
        sentryCapture(e);
    }
}

export function getItem(key) {
    try {
        const type = getAvailableStorage();
        return window[type].getItem(key);
    } catch (e) {
        sentryCapture(e);
    }
}

export function removeItem(key) {
    try {
        const type = getAvailableStorage();
        window[type].removeItem(key);
    } catch (e) {
        sentryCapture(e);
    }
}
